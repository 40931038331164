<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Pagina niet gevonden" />
    <h1>De opgevraagde pagina kon niet gevonden worden</h1>
    <p>
      De opgevraagde pagina kon niet gevonden worden
      <router-link :to="{ name: 'index' }">klik hier</router-link> om terug te
      gaan naar ons assortiment.
    </p>
  </b-container>
</template>

<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs }
}
</script>

<style></style>
